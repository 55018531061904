import 'core-js/stable';
import 'regenerator-runtime/runtime';
import process from 'process';

(window as any).global = window;
if (global === undefined) {
  const global = window;
}

global.process = process;
